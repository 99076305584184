import {S3Object} from "./s3Object";
import {ProjectResourceManager} from "./projectResourceManager";

export class TransferConsumer {
    private resourceManager: ProjectResourceManager;

    constructor(resManager: ProjectResourceManager) {
        this.resourceManager = resManager;
    }

    public start() {
        let that = this;
        let transfer: S3Object = this.resourceManager.queuedTransfers.shift();
        if (!transfer) return;
        this.resourceManager.numCurrentTransfers++;
        console.log("TransferConsumer starting transfer " + transfer.key);
        this.resourceManager.getImageData(transfer, function () {
                console.log("TransferConsumer finished transfer " + transfer.key);
                that.resourceManager.numCurrentTransfers--;
                that.start();
            },
            null
        );
    }

    public stop() {
    }
}