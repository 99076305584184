import {EventEmitter, Injectable} from "@angular/core";
import {WireframeApplication} from "../../application/wireframeApplication";
import {UserActivity} from "../../models/userActivity";
import {Subscription} from "rxjs";
import {UserModel} from "../../models/users/user.model";
import {LogLevel} from "../../application/logLevel";
import * as firebase from 'firebase/app';

@Injectable({
    providedIn: 'root',
})
export class UserActivityService {
    public userActive = new EventEmitter<UserActivity>();
    activityUpdateInterval = 1000 * 15
    lastUserActivity:number = null
    lastActivityUpdate:number = null
    isUserModifying:boolean = false
    isInitialized:boolean = false
    private currentIdleState:boolean = false
    private firebaseConnected = false
    user:any;
    userActiveSubscription:Subscription

    private started:boolean = false

    constructor(private wfApp:WireframeApplication) {
    }

    public start() {
        if (this.started) return
        let that = this
        this.checkFirebaseConnected()
        this.wfApp.apiService.getUser(0).then(
            (userResponse: any) => {
                if (userResponse.data.success) {
                    let currentUser = userResponse.data.data;
                    that.user = new UserModel().deserialize(currentUser);                    
                }
            },
            (err) => {
                
            });
        //this.wfApp.on('userActive', this.onUserActive.bind(this));
        this.wfApp.eventEmitter.on("userActive").subscribe((e:UserActivity) => that.onUserActive(e))
        this.lastUserActivity = new Date().getTime()
        setInterval(function() {
            that.logActivity()
        }, this.activityUpdateInterval)
        this.started = true
    }

    public onUserActive(userActivity:UserActivity) {
        this.registerUserActivity(userActivity)
        this.userActive.emit(userActivity)
    }

    registerUserActivity(activity:UserActivity) {
        if (!this.user) return
        if (activity.isModifying) {
            this.isUserModifying = true
        }
        this.lastUserActivity = new Date().getTime()
        this.checkIdle()
    }

    private checkFirebaseConnected() {
        let self = this
        var connectedRef = firebase.database().ref(".info/connected");
        connectedRef.on("value", function(snap) {
            if (snap.val() === true) {
                if (!self.firebaseConnected) {
                    self.wfApp.log("Firebase Connected", LogLevel.INFO)
                }
                self.firebaseConnected = true
            } else {
                if (self.firebaseConnected) self.wfApp.log("Firebase Disconnected", LogLevel.ERROR)
                self.firebaseConnected = false
            }
            setTimeout(() => self.checkFirebaseConnected(), 1000)
        });
    }

    private setIdle(idle:boolean) {
        if (idle != this.currentIdleState) {
            console.log("setIdle " + idle)
            this.currentIdleState = idle
            if (idle) {

            } else {
                this.lastActivityUpdate = new Date().getTime()
                this.logActivity()
            }
        }
    }

    private checkIdle():boolean {
        let newIdleState = (new Date().getTime() > this.lastUserActivity + (2 * this.activityUpdateInterval));
        this.setIdle(newIdleState)
        return this.currentIdleState
    }

    private initActivity() {
        if (!this.wfApp.userActivity || !this.firebaseConnected) {
            console.log("userActivity ref not initialized")
            return
        }
        try {


            this.lastActivityUpdate = new Date().getTime();
            let userData = {};
            let userKey = "user-" + this.user.id;
            try {
                userData["id"] = this.user.id;
                userData["email"] = this.user.email;
                userData["lastActivity"] = new Date().getTime();
                userData["firstName"] = this.user.firstName ? this.user.firstName : "";
                userData["lastName"] = this.user.lastName ? this.user.lastName : "";
                try {
                    userData["byStatus"] = this.wfApp.userActivity.byUser[userKey].byStatus
                } catch (e) {
                }
                if (!userData["byStatus"]) userData["byStatus"] = {}
            } catch (e) {
                console.log("error initializing user activity", e);
            }
            let userActivityUpdates = {}
            userActivityUpdates["byUser/" + userKey] = userData
            this.wfApp.userActivityRef.update(userActivityUpdates)
            this.isInitialized = true
            //this.logActivity()
        } catch (e) {
            this.wfApp.log("Error initializing user activity", LogLevel.ERROR, e)
        }
    }

    logActivity() {
        if (!this.wfApp.userActivity.projectId) return
        if (!this.user) return
        if (!this.isInitialized) this.initActivity()
        if (this.checkIdle()) return
        if (!this.firebaseConnected) return
        try {
            let updates = {};
            let userKey = "user-" + this.user.id;
            let baseKey = "activity/" + userKey;

            let timeToAdd = Math.min(new Date().getTime() - this.lastActivityUpdate, this.activityUpdateInterval);
            //console.log("logActivity " + (timeToAdd / 1000))
            updates[baseKey + "/lastActivity"] = new Date().getTime();


            //if (!this.projectData.activity.byStatus) updates[baseKey + "/byStatus"] = {}
            let statusTimes = null
            try {
                statusTimes = this.wfApp.userActivity.byUser[userKey].byStatus[this.wfApp.projectData.summary.internalStatus]
            } catch (e) {
            }
            if (!statusTimes) statusTimes = {
                totalTime: 0,
                modifyingTime: 0,
                firstActivity: new Date().getTime(),
                lastActivity: new Date().getTime()
            }

            let userActivityUpdates = {}; //{ projectId: this.projectData.summary.id}

            userActivityUpdates["byUser/" + userKey + "/lastActivity"] = new Date().getTime()
            statusTimes.lastActivity = new Date().getTime()
            if (!statusTimes.totalTime) statusTimes.totalTime = 0
            statusTimes.totalTime += timeToAdd
            if (this.isUserModifying) {
                statusTimes.modifyingTime += timeToAdd
            }
            userActivityUpdates["byUser/" + userKey + "/byStatus/" + this.wfApp.projectData.summary.internalStatus] = statusTimes

            this.wfApp.userActivityRef.update(userActivityUpdates)

            this.isUserModifying = false
            this.lastActivityUpdate = new Date().getTime();
        } catch (e) {
            this.wfApp.log("Error logging user activity", LogLevel.ERROR, e)
        }
    }
}
